<template>
  <div
    class="main-section-header"
    :class="{ 'sticky-header': isSticky, 'smaller-header': isSmaller, 'list-title': subtitle }"
  >
    <div class="title-wrapper">
      <h1>
        <button v-if="hasBackButton" @click="$emit('back')">
          <vue-material-icon name="arrow_back" />
        </button>
        {{ title }}
        <span v-if="selectedItemName">{{ selectedItemName }}</span>
      </h1>
      <p v-if="subtitle" v-html="subtitle" />
    </div>
    <div class="button-wrapper">
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSectionHeader',
  props: ['title', 'subtitle', 'isSmaller', 'selectedItemName', 'hasBackButton', 'isSticky'],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'MainSectionHeader';
</style>
