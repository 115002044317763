var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-section-header",
      class: {
        "sticky-header": _vm.isSticky,
        "smaller-header": _vm.isSmaller,
        "list-title": _vm.subtitle,
      },
    },
    [
      _c("div", { staticClass: "title-wrapper" }, [
        _c("h1", [
          _vm.hasBackButton
            ? _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("vue-material-icon", { attrs: { name: "arrow_back" } })],
                1
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _vm.selectedItemName
            ? _c("span", [_vm._v(_vm._s(_vm.selectedItemName))])
            : _vm._e(),
        ]),
        _vm.subtitle
          ? _c("p", { domProps: { innerHTML: _vm._s(_vm.subtitle) } })
          : _vm._e(),
      ]),
      _c("div", { staticClass: "button-wrapper" }, [_vm._t("button")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }